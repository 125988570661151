body {
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Open Sans', source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}
html,
body {
  height: 100%;
  width: 100%;
  /* overflow-y: scroll;
  -webkit-overflow-scrolling: touch; */
}

.MuiContainer-root {
  padding: 10px !important;
}

.MuiTableCell-footer {
  padding: 0 !important;
}

.MuiExpansionPanelDetails-root {
  padding: 10px !important;
}

.MuiTablePagination-actions {
  margin-left: -5px !important;
}
/* 
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; */
/* ios7 scroll bug fixes. this is usually used for the skipnav link, but is a convenient hook for these styles*/
/* z-index: 100000;
  position: fixed;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
html.ios7 body {
  height: 100%;
  position: fixed;
  width: 100%;
}
 */

.outer-circle-1 {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  padding: 2px;
  border: 1px solid #051f34;
}
.outer-circle-2 {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  padding: 3px;
  border: 2px solid #051f34;
}
.circle {
  border-radius: 50%;
  width: 34px;
  height: 34px;
  padding: 10px;
  background: #051f34;
  /* border: 1px solid #051F34; */
  color: #fff;
  text-align: center;
  font: 32px Arial, sans-serif;
}

.H_ib_body {
  background: transparent !important;
  box-shadow: none !important;
  right: auto !important;
  top: -45px !important;
  left: 20px !important;
}

.H_ib_tail {
  display: none !important;
}
.principal-container {
  cursor: pointer !important;
}

.H_ib_close {
  /* display: none !important; */
  transform: translate(-19px, -41px) !important;
}

em {
  font-style: inherit !important;
}

@import '~react-vis/dist/style.css';
